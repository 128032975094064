if (document.querySelectorAll('.waterguide-links--off').length < 1) {
   if (document.querySelector('.mod_article').querySelectorAll('.block').length > 0) {
      Array.from(document.querySelector('.mod_article').querySelectorAll('.block')).forEach((block) => {
         if (!block.classList.contains('badge') && !block.classList.contains('ce_rsce_hero')) {
            //console.log(block);
            if (block.getElementsByTagName('p').length > 0) {
               Array.from(block.getElementsByTagName('p')).forEach((p) => {
                  if (!p.classList.contains('more') && !p.classList.contains('button-link')) {
                     let cp = p.innerHTML;
                     for (var i = 0; i < WGLinkArray.length; i++) {
                        var str = WGLinkArray[i][0];
                        var tag_url = "";
                        var aliasArr = window.location.href.split('/');
                        var alias = aliasArr[aliasArr.length - 1];
                        alias = alias.replace(".html", "");
                        if (WGLinkArray[i][1] != alias) {
                           if (document.documentElement.lang === "de") {
                              tag_url = "/de/waterguide-wassertechnik/" + WGLinkArray[i][1] + ".html";
                           } else {
                              tag_url = "/en/waterguide-water-technology/" + WGLinkArray[i][1] + ".html";
                           }
                           cp = cp.replace(str + " ", '<span class="waterguide-link" data-ref="' + tag_url + '">' + str + '</span> ');
                           cp = cp.replace(" " + str + ".", ' <span class="waterguide-link" data-ref="' + tag_url + '">' + str + '</span>.');
                           cp = cp.replace(str + ",", '<span class="waterguide-link" data-ref="' + tag_url + '">' + str + '</span>,');
                           if (document.documentElement.lang === "en") {
                              if (str != str.toLowerCase()) {
                                 cp = cp.replace(str.toLowerCase() + " ", '<span class="waterguide-link" data-ref="' + tag_url + '">' + str.toLowerCase() + '</span> ');
                                 cp = cp.replace(" " + str.toLowerCase() + ".", ' <span class="waterguide-link" data-ref="' + tag_url + '">' + str.toLowerCase() + '</span>.');
                                 cp = cp.replace(str.toLowerCase() + ",", '<span class="waterguide-link" data-ref="' + tag_url + '">' + str.toLowerCase() + '</span>,');
                              }
                           }
                        }
                     }
                     p.innerHTML = cp;
                  }
               });
            }
         }
      });
   }
}

if (document.querySelectorAll('span.waterguide-link').length > 0) {
   Array.from(document.querySelectorAll('span.waterguide-link')).forEach((link) => {
      //const hasParentLink = isDescendant("A", link);
      //if (!hasParentLink) {
      if (link.parentNode.tagName != "A" && link.parentNode.parentNode.tagName != "A") {
         var atag = document.createElement('a');
         atag.setAttribute('href', link.getAttribute('data-ref'));
         atag.classList.add('waterguide-link');
         while (link.firstChild) {
            atag.appendChild(link.firstChild);
         }
         link.parentNode.replaceChild(atag, link);
      }
   });
}


